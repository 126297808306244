import ApiService from "@/core/services/api.service";

export default {

    // -----API DATA OPTIONS------
    getFlock(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/populasi/master/flock", { params: credentials })
    },
    getKandang(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/populasi/master/kandang", { params: credentials })
    },


    // -----API DATA LIST------
    getFarmList(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/populasi/farm", { params: credentials })
    },
    getFlockList(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/populasi/flock", { params: credentials })
    },
    getKandangList(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/populasi/kandang", { params: credentials })
    },
    getHenHouseList(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/populasi/hen-house", { params: credentials })
    },
    getHenHouseSexList(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/nasional/populasi/hen-house", credentials)
    },
    getHenHouseLineList(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/nasional/populasi/hen-house/line", credentials)
    },
    getDistribusiList(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/populasi/distribusi", { params: credentials })
    },


    // -----API HISTORY LIST------
    getRiwayatChickin(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/populasi/chickin", { params: credentials })
    },
    getRiwayatDeplesi(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/populasi/deplesi", { params: credentials })
    },
    getRiwayatFeedInTake(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/populasi/feedintake", { params: credentials })
    },
    getRiwayatTransfer(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/populasi/transfer", { params: credentials })
    },
    getRiwayatAfkir(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/populasi/afkir", { params: credentials })
    },
    getRiwayatProduksi(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/populasi/produksi-egg", { params: credentials })
    },

    // -----API HISTORY EDIT------
    getChickinEdit(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/populasi/chickin/edit", { params: credentials })
    },
    getDeplesiEdit(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/populasi/deplesi/edit", { params: credentials })
    },
    getFeedInTakeEdit(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/populasi/feedintake/edit", { params: credentials })
    },
    getTransferEdit(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/populasi/transfer/edit", { params: credentials })
    },
    getAfkirEdit(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/populasi/afkir/edit", { params: credentials })
    },
    getProduksiEggEdit(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/populasi/produksi-egg/edit", { params: credentials })
    },


    // ------API PROCESS DATA------
    addChickin(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/nasional/populasi/chickin", credentials)
    },
    updateChickinFromDistribusi(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/nasional/populasi/distribusi", credentials)
    },
    updateChickin(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/nasional/populasi/chickin", credentials)
    },
    deleteChickin(credentials) {
        ApiService.setHeader();
        return ApiService.delete("v2/nasional/populasi/chickin", { data: credentials })
    },


    addDeplesi(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/nasional/populasi/deplesi", credentials)
    },
    updateDeplesi(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/nasional/populasi/deplesi", credentials)
    },
    deleteDeplesi(credentials) {
        ApiService.setHeader();
        return ApiService.delete("v2/nasional/populasi/deplesi", { data: credentials })
    },


    addFeedInTake(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/nasional/populasi/feedintake", credentials)
    },
    updateFeedInTake(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/nasional/populasi/feedintake", credentials)
    },
    deleteFeedInTake(credentials) {
        ApiService.setHeader();
        return ApiService.delete("v2/nasional/populasi/feedintake", { data: credentials })
    },


    addTransfer(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/nasional/populasi/transfer", credentials)
    },
    updateTransfer(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/nasional/populasi/transfer", credentials)
    },
    deleteTransfer(credentials) {
        ApiService.setHeader();
        return ApiService.delete("v2/nasional/populasi/transfer", { data: credentials })
    },


    addAfkir(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/nasional/populasi/afkir", credentials)
    },
    updateAfkir(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/nasional/populasi/afkir", credentials)
    },
    deleteAfkir(credentials) {
        ApiService.setHeader();
        return ApiService.delete("v2/nasional/populasi/afkir", { data: credentials })
    },


    addProduksiEgg(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/nasional/populasi/produksi-egg", credentials)
    },
    updateProduksiEgg(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/nasional/populasi/produksi-egg", credentials)
    },
    deleteProduksiEgg(credentials) {
        ApiService.setHeader();
        return ApiService.delete("v2/nasional/populasi/produksi-egg", { data: credentials })
    },
}